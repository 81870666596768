import VerifiedUserOutlined from "@mui/icons-material/VerifiedUserOutlined";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import CameraAltOutlined from "@mui/icons-material/CameraAltOutlined";
import MessageOutlined from "@mui/icons-material/MessageOutlined";
// import CreditCard from "@mui/icons-material/CreditCard";

import { useUser } from "../contexts/UserContext";

const useOnboard = () => {
  const { user } = useUser();

  const showOnBoarding = () => {
    if (!user.helper || !user.helper.backgroundCheck) {
      return false;
    }

    if (
      !user._welcomeStatus.backgroundCheckSubmitted ||
      !user._welcomeStatus.hasAvailability
    ) {
      return true;
    }

    return false;
  };

  const getButtons = () => {
    if (!user.helper) {
      return [];
    }

    return [
      {
        label: "Add a profile photo",
        url: "/welcome/photo",
        welcomeUrl: "/welcome/photo",
        isComplete: user.helper.profile.profilePicture ? true : false,
        icon: <CameraAltOutlined />,
        types: ["helper"],
        key: "photo",
      },
      {
        label: "Add a bio",
        url: "/welcome/about",
        welcomeUrl: "/welcome/about",
        isComplete: user.helper.profile.description ? true : false,
        icon: <MessageOutlined />,
        types: ["helper"],
        key: "about",
      },
      {
        label: "Set availability",
        url: "/welcome/availability",
        welcomeUrl: "/welcome/availability",
        isComplete: user._welcomeStatus.hasAvailability,
        icon: <CalendarTodayOutlined />,
        types: ["helper"],
        key: "availability",
      },
      {
        label: "Complete background check",
        url: "/welcome/background",
        welcomeUrl: "/welcome/background",
        isComplete: user._welcomeStatus.backgroundCheckClear,
        icon: <VerifiedUserOutlined />,
        types: ["helper"],
        key: "background",
      },
    ];
  };

  const getNext = () => {
    if (!user.helper) {
      return [];
    }

    let incomplete = null;

    getButtons().forEach((btn, btnIdx) => {
      if (btn.isComplete || incomplete) {
        return;
      }

      incomplete = { ...btn, _index: btnIdx };
    });

    return incomplete;
  };

  return {
    showOnBoarding: showOnBoarding(),
    welcomeNav: getButtons(),
    next: getNext(),
  };
};

export default useOnboard;
