import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Badge } from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import InternalLayout from "../layout/InternalLayout";
import { useApiGet } from "../../hooks/useApi";
import { getCheckrBaseWebUrl } from "../../lib/envHelpers";

const BackgroundCheckStatus = () => {
  const { updateUser } = useUser();
  const { isLoading, data } = useApiGet(
    "user-profile",
    "/users/profile",
    null,
    {
      staleTime: 5000,
      onError: (err) => toast.error(err.message),
      onSuccess: (res) => updateUser(res),
    }
  );
  const navigate = useNavigate();

  const render = () => {
    if (isLoading || !data) {
      return <p>Loading...</p>;
    }

    if (!data.helper.backgroundCheck || !data.helper.backgroundCheck.checkrId) {
      navigate("/background-check");
      return;
    }

    if (data.helper.backgroundCheck.status === "clear") {
      return (
        <>
          <h2 className="mb-4">
            Background Check Status: <Badge bg="success">CLEAR</Badge>
          </h2>
          <p className="body1">
            Your background check came back clear! Welcome to Manana!
          </p>
          <p>
            <a
              href={`${data.helper.backgroundCheck.checkrReportUrl}`}
              target="_blank"
              rel="noreferrer"
              className="btn btn-secondary"
            >
              VIEW YOUR REPORT
            </a>
          </p>
        </>
      );
    }

    if (data.helper.backgroundCheck.status === "not eligible") {
      return (
        <>
          <h2 className="mb-4">
            Background Check Status: <Badge bg="danger">NOT ELIGIBLE</Badge>
          </h2>
          <p className="body1">
            It looks like we received some negative information on your
            background check. Please look for your background check report from
            Checkr with more details. To dispute this information, please follow
            the directions in the Checkr email.
          </p>
          <p>
            <a
              href={`${getCheckrBaseWebUrl()}${
                data.helper.backgroundCheck.checkrReportUrl
              }`}
              target="_blank"
              rel="noreferrer"
              className="btn btn-secondary"
            >
              VIEW YOUR REPORT
            </a>
          </p>
        </>
      );
    }

    if (data.helper.backgroundCheck.status === "needs review") {
      return (
        <>
          <h2 className="mb-4">
            Background Check Status: <Badge bg="warning">NEEDS REVIEW</Badge>
          </h2>
          <p className="body1">
            Your background check came back with information that could
            potentially disqualify you from joining Manana. Please contact
            support for more information. To dispute this information, please
            follow the directions in the Checkr email.
          </p>
          <p>
            <a
              href={`${getCheckrBaseWebUrl()}${
                data.helper.backgroundCheck.checkrReportUrl
              }`}
              target="_blank"
              rel="noreferrer"
              className="btn btn-secondary"
            >
              VIEW YOUR REPORT
            </a>
          </p>
        </>
      );
    }

    return (
      <>
        <h2 className="mb-4">
          Background Check Status:{" "}
          <Badge bg="info">
            {data.helper.backgroundCheck.status.toUpperCase()}
          </Badge>
        </h2>
        <p className="body1">
          Your background check is currently in{" "}
          {data.helper.backgroundCheck.status}. Use the link below to complete,
          update or check on your background check.
        </p>
        <a
          href={data.helper.backgroundCheck.checkrInviteUrl}
          target="_blank"
          rel="noreferrer"
          className="btn btn-secondary"
        >
          Go To Checkr
        </a>
      </>
    );
  };

  return (
    <InternalLayout>
      <Helmet>
        <title>Background Check Status | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>{render()}</Container>
    </InternalLayout>
  );
};

export default BackgroundCheckStatus;
