import React, { useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment-timezone";

import { useApi } from "../../../hooks/useApi";
import { useFormData } from "../../../hooks/useFormData";
import FileUploadButton from "../../common/FileUploadButton";

const DEFAULT_VALS = {
  startTime: "",
  endTime: "",
  estimatedTime: 2,
  notes: "",
  fileUrl: null,
};

const BookingEditForm = ({ booking, onSuccess, onCancel }) => {
  const { formData, onChange, setData } = useFormData({ ...DEFAULT_VALS });

  const { call, isLoading } = useApi({
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (booking) {
      setData({
        startTime: moment(booking.startDate).format("HH:mm"),
        endTime: moment(booking.endDate).format("HH:mm"),
        estimatedTime: booking.estimatedTime,
        reauth: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const onSubmit = (e) => {
    e.preventDefault();

    const parts = formData.startTime.split(":");
    const startDt = moment(booking.startDate).set({
      hour: Number(parts[0]),
      minute: Number(parts[1]),
    });

    const payload = {
      startDate: startDt.toDate(),
      endDate: moment(startDt).add(formData.estimatedTime, "hours").toDate(),
      estimatedTime: Number(formData.estimatedTime),
      notes: formData.notes,
      fileUrl: formData.fileUrl,
    };

    call(
      `/admin/job-requests/${booking.jobRequest._id}/events/${booking._id}`,
      "put",
      undefined,
      payload,
      () => {
        onSuccess();
      }
    );
  };

  const onTimeChange = (e) => {
    const isStart = e.target.name === "startTime";
    let startDt = moment(booking.startDate);
    let endDt = moment(booking.endDate);
    let updates = {};

    if (isStart) {
      // we want to move the end time forward to maintain the hour difference
      startDt = moment(
        `${startDt.format("YYYY-MM-DD")} ${e.target.value}`,
        "YYYY-MM-DD HH:mm"
      );
      endDt = moment(startDt).add(formData.estimatedTime, "hours");

      updates = {
        startTime: startDt.format("HH:mm"),
        endTime: endDt.format("HH:mm"),
      };
    } else {
      // we want to calculate the new hour difference between start and end
      startDt = moment(startDt);
      endDt = moment(
        `${endDt.format("YYYY-MM-DD")} ${e.target.value}`,
        "YYYY-MM-DD HH:mm"
      );
      const mins = endDt.diff(startDt, "minutes");

      updates = {
        startTime: startDt.format("HH:mm"),
        endTime: endDt.format("HH:mm"),
        estimatedTime: mins / 60,
      };
    }

    setData({ ...updates }, true);
  };

  const onHourChange = (e) => {
    let endDt = moment(booking.startDate).add(e.target.value, "hours");
    setData({ endTime: endDt.format("HH:mm"), estimatedTime: e.target.value });
  };

  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3 required">
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={onTimeChange}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3 required">
            <Form.Label>End Time</Form.Label>
            <Form.Control
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={onTimeChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3 required">
            <Form.Label>Hours</Form.Label>
            <Form.Control
              type="number"
              name="estimatedTime"
              min={1}
              max={23}
              step={0.5}
              value={formData.estimatedTime}
              onChange={onHourChange}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          &nbsp;
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              onChange={onChange}
              value={formData.notes}
              placeholder="Optional notes..."
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Additional File</Form.Label>
            {formData.fileUrl ? (
              <p>
                <a href={formData.fileUrl} target="_blank" rel="noreferrer">
                  View Uploaded File
                </a>
              </p>
            ) : null}
            <div>
              <FileUploadButton
                onUpload={(url) =>
                  onChange({ target: { name: "fileUrl", value: url } })
                }
              />
            </div>
          </Form.Group>
        </Col>
      </Row>

      <div className="mt-4">
        <Button type="submit" variant="primary" disabled={isLoading}>
          Save Changes
        </Button>
        <Button variant="link" onClick={() => onCancel()} disabled={isLoading}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default BookingEditForm;
