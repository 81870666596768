import React, { useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import useOnboard from "../../hooks/useOnboard";
import { useFormData } from "../../hooks/useFormData";
import InternalLayout from "../layout/InternalLayout";
import WelcomeSteps from "./components/WelcomeSteps";
import ContentBlock from "../common/ContentBlock";
import StepProgress from "../common/StepProgress";
import {
  InteralLayoutStyled,
  CardContainer,
} from "./components/Welcome.elements";
import { ButtonFooter } from "../welcome/components/Welcome.elements";

const WelcomeBackgroundLegal = () => {
  const { showOnBoarding } = useOnboard();
  const { formData, onChange } = useFormData({
    acknowledged: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!showOnBoarding) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    navigate("/welcome/background/pay");
  };

  const content = (
    <form onSubmit={onSubmit}>
      <h3 className="mb-4">
        We need your permission to initiate the background check.
      </h3>
      <p className="body1">
        This is the minimum amount of information legally required to perform a
        background check.
      </p>
      <p className="subtitle2 mb-4">
        <span className="body2">1. Introduction.</span>
        &nbsp;The Company is committed to ensuring that the work environment is
        as safe as possible. The Company is also committed to protecting its
        resources and assets. In this regard, the Company has adopted this
        background check policy. The Company will use all information obtained
        during background checks solely for evaluating job applicants' or
        employees' suitability for employment with Company.
      </p>
      <p className="subtitle2 mb-4">
        <span className="body2">2. Scope of Background Checks.</span>
        &nbsp;The Company may conduct background checks on job applicants and
        employees concerning references and prior employment, as well as
        educational, criminal, and credit history, to the extent permitted by
        federal, state, and local laws. The Company will determine the nature
        and scope of the background check. The background check will be
        consistent with the needs of the job applicant's at employee's position.
      </p>
      <p className="subtitle2 mb-4">
        <span className="body2">3. Consent to Background Checks.</span>
        &nbsp;The Company will ask job applicants of employees to sign a consent
        form authenticating the Company to conduct a background search. If job
        applicants of employees refuse to sign the consent form, the Company may
        no longer consider job applicants as candidates for employment of
        employees may be subject to discipline, up to and including termination.
        If the Company discovers that job applicants of employees falsified or
        emitted information on consent forms, job applicants may be subject to
        discipline, up to and including termination.
      </p>
      <p className="subtitle2 mb-4">
        <span className="body2">4. Confidentiality.</span>
        &nbsp;The information obtained through background checks is confidential
        and will be shared only with individuals with an essential business need
        to know.
      </p>
      <p className="subtitle2 mb-4">
        <span className="body2">
          5. Offers of Employment and Continued Employment.
        </span>
        &nbsp;An offer of employment made by the Company or continued employment
        may be the Company or continued employment may be contingent upon the
        successful completion of a background check.
      </p>
      <p className="body2">Acknowledgment.</p>
      <Form.Group className="mb-3" controlId="cb-background">
        <Form.Check
          type="checkbox"
          label="I acknowledge that I have received, read, understand, and will abide by the Company's Background Check Policy. I understand that this policy is not an employment contract and does not change my status as an at-will employee."
          value={true}
          id="cb-background"
          checked={formData.acknowledged}
          size="lg"
          onChange={(e) =>
            onChange({
              target: {
                name: "acknowledged",
                value: e.target.checked,
              },
            })
          }
        />
      </Form.Group>

      <hr />

      <ButtonFooter className="mb-4">
        <Row>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }}>
            <Button
              type="button"
              variant="light"
              className="btn-text-primary w-90"
              onClick={() => {
                navigate("/welcome");
              }}
            >
              SKIP FOR NOW
            </Button>
          </Col>
          <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }}>
            <Button
              type="submit"
              variant="primary"
              className="w-90"
              style={{ minWidth: 200, fontFamily: "Noto Sans" }}
              disabled={!formData.acknowledged}
            >
              NEXT
            </Button>
          </Col>
        </Row>
      </ButtonFooter>
      <div className="d-flex justify-content-between">
        <LockOutlinedIcon style={{ marginRight: "0.5rem" }} />
        <p className="body1 text-grey">
          Your personal information is securely encrypted and never shared
          without your expressed consent.
        </p>
      </div>
    </form>
  );

  return (
    <InteralLayoutStyled>
      <Helmet>
        <title>Background Check Legal | Welcome | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <InternalLayout showNav={false} internalPageClass="welcome-background">
        <CardContainer className="mb-5 text-center">
          <WelcomeSteps
            currentStep={4}
            totalSteps={4}
            onBack={() => navigate("/welcome/background")}
          />
          <ContentBlock>
            <StepProgress
              steps={["Information", "Legal", "$35 Fee"]}
              active={1}
            />
          </ContentBlock>

          <ContentBlock style={{ padding: "3rem 1rem" }}>
            {content}
          </ContentBlock>
        </CardContainer>
      </InternalLayout>
    </InteralLayoutStyled>
  );
};

export default WelcomeBackgroundLegal;
